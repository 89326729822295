<!--
 * ======================================
 * 说明： 教师端 - 日常发卡
 * 作者： Silence
 * 文件： grant.vue
 * 日期： 2023/8/3 0:28
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <div class="mx-6 pl-4 pt-8">
      <el-form :inline="true" :model="formSearch" class="demo-form-inline">
        <SemesterClassSelect v-model="formSearch" @onLoadFinish="handleLoadDataInfo" @change="handleRefresh" @refresh="handleRefresh"></SemesterClassSelect>
      </el-form>
    </div>
    <Scrollbar>
      <Table :autoLoading="false" :hasAction="true" :options="handleOptions" :loadData="handleLoadData" ref="table">
<!--        <template #search>-->
<!--          <el-form :inline="true" :model="formSearch" class="demo-form-inline">-->
<!--            <el-form-item label="学号">-->
<!--              <el-input v-model="formSearch.number" placeholder="学号">-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="姓名">-->
<!--              <el-input v-model="formSearch.realName" placeholder="姓名">-->
<!--              </el-input>-->
<!--            </el-form-item>-->

            <!--            <el-form-item label="卡名称">-->
            <!--&lt;!&ndash;              <el-input v-model="formSearch.card_name" placeholder="卡名称"></el-input>&ndash;&gt;-->
            <!--              <el-select class="w-40" v-model="formSearch.cardId" clearable>-->
            <!--                <el-option v-for="item in medalList" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--              <el-button type="primary" @click="handleRefresh">查询</el-button>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
<!--        </template>-->
        <template #action>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="handleCreate">发卡</el-button>
            <!--            <el-button type="primary" icon="el-icon-plus" @click="handleExport">导出</el-button>-->
          </div>
        </template>
        <template #handle="{index,row}">
          <el-button type="text" @click="handleDetail(row)">详情</el-button>
          <el-button type="text" @click="handleRecording(row)">记录</el-button>
        </template>
      </Table>
    </Scrollbar>
  </div>
</template>
<script type="text/javascript">
import edit from './components/grantEdit.vue'
import detail from './components/grantDetail.vue'
import recording from './components/grantRecording.vue'
import {grade} from '@/utils/global.js'

export default {
  name: "medal",
  components: {},
  data() {
    return {
      medalList: [],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        semesterId: '',
        classId: '',
        cardId: '',
      },
    };
  },
  async created() {
    await this.handleLoadMedal()
  },
  methods: {
    handleLoadDataInfo() {
      this.handleRefresh();
    },

    // 获取卡
    async handleLoadMedal() {
      await this.$cloud.get('card/list', {
        page: 1,
        size: 99999,
      }).then(res => {
        this.medalList = []
        if (res?.children?.length) {
          this.medalList = res.children
        }
      })
    },

    handleOptions(table) {
      console.log("获取表格数据源", table)
      //获取分页数据结构
      this.$cloud.get("header/web/grant").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}, search = {}) {
      //获取分页数据结构
      this.$cloud.get("card/student/list", {size, page, ...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },

    // 发卡
    handleCreate() {
      this.$cloud.dialog({
        title: '发卡',
        data: {
          grade: this.formSearch.grade,
          classId: this.formSearch.classId,
          semesterId: this.formSearch.semesterId
        },
        component: edit,
        success: '发卡成功!',
        warning: '发卡失败!',
        customClass: 'grant-dialog-class',
        refresh: this.handleRefresh
      })
    },

    // 详情
    handleDetail(row) {
      this.$cloud.dialog({
        title: '发卡详情',
        data: {
          ...row,
          semesterId: this.formSearch.semesterId,
          classId: this.formSearch.classId,
        },
        component: detail,
        success: '发卡详情!',
        warning: '发卡详情!',
      })
    },

    handleRecording(row) {
      this.$cloud.dialog({
        title: '发卡记录',
        data: {
          ...row,
          semesterId: this.formSearch.semesterId,
          classId: this.formSearch.classId,
        },
        component: recording,
        success: '发卡记录!',
        warning: '发卡记录!',
        customClass: 'grant-dialog-class',
      })
    },

    // 导出
    handleExport() {
      this.$confirm('确定要导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '导出成功!'
        })
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    },
    changeClass(e) {
      console.log("班级选择",e)
    }
  },
};
</script>
<style lang="scss">
.grant-dialog-class {
  width: 800px;
}
</style>
